import { instructionToken, sampleQuestions } from ".";
import { createChat, deleteChat, listChats, listMessages, patchChat, sendUserMessage } from "../service/requests";


export const assistantConfig = {
    createChat,
    listChats,
    patchChat,
    deleteChat,
    listMessages,
    sendUserMessage,
    instructionToken: instructionToken,
    sampleQuestions: sampleQuestions
}