export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
export const ASSISTANT_ID = process.env.REACT_APP_OPENAI_ASSISTANT_ID;
export const VECTOR_STORE_ID = process.env.REACT_APP_VECTOR_STORE_ID;
export const MODEL_ID = process.env.REACT_APP_MODEL_ID || "gpt-4o-2024-11-20"; // Update default model
export const TEMPERATURE = 0.5;
export const TOP_P = 1.0;


export const instructionToken = ` (Please find a relevant document(s) in your knowledge base and use that to answer me. Always provide specific examples and step by step exercises when appropriate. If you can't find answers in your knowledge-base, simply reply "I don't have information on that topic". Your output should be at least 4000 tokens.)`;

export const sampleQuestions = [
  "How can I help my violin student grasp and convey the structure and phrasing of Bach's G minor Presto?",
  "How can I improve my finger dexterity for fast passages, especially in pieces like Paganini Caprices?",
  "What techniques can help me master spiccato and sautillé bow strokes?",
  "How do I reduce tension in the hand while shifting",
  "How can I develop a more expressive and varied vibrato?",
  "What exercises can help me maintain evenness and control in my bowing, particularly during string crossings?",
  "How can I improve my left-hand finger independence and strength?",
  "What are some advanced techniques for playing chords cleanly and in tune?",
  "How can I improve my martelé stroke?",
  "What are some ways to improve my dynamics and dynamic range?",
  "How can I develop better coordination between my left and right hands, especially in complex passages?",
  "How can I improve my ability to play fast, clean trills?",
  "What exercises can help me develop a more fluid and connected legato bowing?",
  "How can I practice to achieve a more powerful and resonant sound on the G-string?",
  "What are some techniques for improving my bow control and articulation at the frog and tip?",
  "How can I practice to develop a more relaxed left-hand technique? I often get tense.",
  "What are some advanced methods for practicing passages?",
  "How can I develop my portamento and slides to enhance style?",
  "What are the best methods to practice rapid string crossings?",
  "How can I improve my bow control and articulation?",
  "What are some techniques for improving my bow changes?",
  "How do I use rhythms technique to practice passages?",
  "How can I practice to achieve a more consistent and controlled martelé stroke in fast passages?",
  "How can I develop better left-hand finger independence and strength?",
  "What are some effective methods for practicing and mastering high positions?",
  "My sound is bumpy and I'm not sure why.",
  "How can I practice extensions so they are more in tune and not tightening my hand?",
  "What are some advanced methods for practicing and mastering difficult passages in concertos and solo pieces?",
  "How can I switch from wrist to arm vibrato effectively?",
  "What exercises improve fast double stops? My fingers get mixed up.",
  "How do I refine my ricochet technique?",
  "What are some quick social media reels to teach finger independence for trills and ornaments?",
  "How can I control slower, louder spiccato?",
  "How do I improve my bow distribution?",
  "Could you plan a one-week mini-course (email or video) on improving vibrato control and consistency?",
  "What's the best way to transition between sautillé and detaché bowing?",
  "How can I improve finger preparation for fast arpeggios across strings?",
  "How can I refine flying staccato at the frog for virtuoso passages?",
  "How can I improve bow articulation in the upper half of the bow?",
  "What exercises strengthen the left-hand pinky?",
  "How do I synchronize finger stroke and bow speed in sautillé?",
  "How can I cleanly execute grace notes and ornaments?",
  "How can I ensure even vibrato across all fingers in slow lyrical passages?",
  "My pinky vibrato is weak and lacks control. How can I improve it?",
  "My detache bowing doesn't produce a full, resonant sound. What can I do to enhance it?",
  "I have pain and tension in my right-hand thumb. How can I alleviate this?",
  "My bow arm feels tense during fast string crossings. Help?",
  "My double stops sound out of tune, especially in higher positions.",
  "My light spiccato feels uncontrolled and inconsistent. How can I refine it?",
  "How can I increase my precision when shifting?",
  "I find it hard to maintain consistent dynamics in long phrases. What strategies can I use to improve this?",
  "My bow hold becomes stiff when playing at the frog. How can I maintain flexibility?",
  "I struggle with finger coordination in rapid passages. What exercises can help me improve?",
  "My intonation in octaves is inconsistent.",
  "Please design a 1.5h workshop on advanced concepts in Sound Production, with timings and various ideas to keep it engaging.",
  "I lose control of my bow when playing soft, slow notes near the tip. How can I improve my control?",
  "Please help me craft a detailed 1-month practice plan focused on scales.",
  "5 social media blog ideas about the most common problems violinists typically face. 2 title options, a paragraph summary of each problem and some ideal hashtags.",
  "How can I convey deeper emotional nuances in my interpretation of Romantic violin pieces?",
  "What are some techniques to more clearly bring out different voices in polyphonic violin works?",
  "How can I develop more convincing phrasing?",
  "Outline a 1-hour workshop on vibrato, including demonstrations and Q&A.",
  "What would a one-week Instagram challenge for improving chord playing look like?",
  "How can I structure a 90-minute masterclass on expressive phrasing in Romantic works?",
  "Suggest a 4-week blog series on improving left-hand agility, with weekly topics and exercises.",
  "Propose a half-day teacher-training workshop on reducing left-hand tension.",
  "What are some dos and donts of rubato? How do I make it organic?",
  "How do Galamian's views on phrasing compare with Auer's?",
  "How do Flesch's scale studies differ from Fischer's?",
  "How does Kató Havas' approach natural feeling in the left-hand?",
  "What are Fischer's and Galamian's views on finger agility or dexterity?",
  "What is Flesch's advice on solving problems with tone production?",
  "What is Auer's view on expressive glissando or portamento?",
  "Propose a blog series on expanding dynamic range, including daily practice tips.",
  "How do I cultivate a personal interpretive voice while honoring the composer's intention?",
];
