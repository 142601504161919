import React, { useEffect, Suspense, useState } from "react";
import { connect, useDispatch } from "react-redux";
import './App.scss';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { currentUser } from "./actions/currentUser";
import { openPopup } from './actions/popup';
import Popup from './components/popup/popup';
import HomePage from './pages/homePage/homePage';
import Library from './pages/library/library';
import SearchPage  from "./pages/searchPage/searchPage";
import EtudesPage  from "./pages/etudesPage/etudesPage";
import ComposerPage  from "./pages/composerPage/composerPage";
import Notification from "./components/notification/notification";
import { getMe } from './service/requests';
import { requestCommonData } from './actions/commonData';
import FilteredPage from "./pages/filteredPage/filteredPage";

import {Assistant} from "@arcomusic-ai/ui";

import { assistantConfig } from "./config/assistant-config";
const mapStateToProps = (state) => ({
  ...state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
});

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

function App(props) {
  const dispatch = useDispatch();
  const { user, currentUser, openPopup } = props;
  // const [authChecked, setAuthChecked] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isAuthChecked, setIsAuthChecked] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("accessToken");


  useEffect(() => {
    if (location.pathname.includes('email/reset')) {
      const search = location.search;
      const params = new URLSearchParams(search);
      const code = params.get('code'); 
      openPopup({ currentPopup: 'PasswordConfirmation', info: { title: 'Password confirmation', code: code } })
    }
  }, [location]);


  const getUser = async () => {
    try {
      const resUser = await getMe();
      if (resUser.data.account) {
        if(resUser.data.account) {
          const { profile_image, avatar } = resUser.data.account;
          const img = profile_image || avatar || 'https://dev.arcomusic.ai/images/profile/default.png';

          resUser.data.account.avatar = img;

          currentUser({user: resUser.data.account});
        }

        setIsAuthenticated(true);
        setIsAuthChecked(true);
      }
    } catch (e) {
        console.log("e", e);
        setIsAuthenticated(false);
        setIsAuthChecked(true);
    }
  }

  useEffect(() => {
    dispatch(requestCommonData());
  }, [token]);

  useEffect(() => {
    if (token != null) {
      const tokenData = parseJwt(token);

      if(tokenData) {
        getUser();
      }
    } else {
      setIsAuthenticated(false);
      setIsAuthChecked(true);
      currentUser({ user: false });
      navigate('/');
    }
  }, [token]);

return (
    <div className="App">
      { isAuthChecked && <div className="">
        <Popup />
        <Notification />
        {isAuthenticated && <Assistant options={assistantConfig}/>}
        <Routes>
          <Route path="/" element={<HomePage isAuthenticated={isAuthenticated}/>} />
          <Route
            path="/library"
            element={
              <ProtectedRoute user={isAuthenticated}>
                <Library />
              </ProtectedRoute>
            }
          />
          <Route
            path="/search"
            element={
              <ProtectedRoute user={isAuthenticated}>
                <SearchPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/etudes/:tagId/:etudeId"
            element={
              <ProtectedRoute user={isAuthenticated}>
                <EtudesPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/composers/:composerId/:opus/:etudeId"
            element={
              <ProtectedRoute user={isAuthenticated}>
                <ComposerPage />
              </ProtectedRoute>
            }
          />

        <Route
            path="/filtered/:composerId/:opus/:tagIds/:etudeId"
            element={
              <ProtectedRoute user={isAuthenticated}>
                <FilteredPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
